import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/main';
import Menu from './pages/menu';
import Login from './pages/admin/login';
import Categories from './pages/admin/categories';
import Subcategories from './pages/admin/subcategories';
import Items from './pages/admin/items';
import Perionica from './pages/perionica';
import NewMenu from './pages/newMenu';
import { Worker } from '@react-pdf-viewer/core';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const lockedRoutes = () => {
  if (localStorage.getItem("token")) {
    return [
      {
        path: "categories",
        element: <Categories />
      },
      {
        path: "subcategories/:id",
        element: <Subcategories />
      },
      {
        path: "items/:id",
        element: <Items />
      }
    ];
  }
  return [];
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/meni/:id",
    element: <Menu />,
  },
  {
    path: "/novimeni",
    element: <NewMenu />,
  },
  {
    path: "/perionica",
    element: <Perionica />,
  },
  {
    path: "/admin",
    children: [
      {
        path: "login",
        element: <Login />
      },
      ...lockedRoutes()
    ]
  }
]);

root.render(
  <React.StrictMode>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"><RouterProvider router={router} /></Worker>
  </React.StrictMode>
);