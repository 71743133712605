import { Viewer } from "@react-pdf-viewer/core"
import '@react-pdf-viewer/core/lib/styles/index.css';

const NewMenu = () => {

    return (
        <>
            <Viewer fileUrl="/combinepdf_repaired-compressed.pdf" />;
            {/* <Viewer fileUrl="https://upfoxstudio.com/meni/noooo.pdf" />; */}
        </>
    )
}

export default NewMenu